var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "210 360 1520 360"
      }
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "a",
                x1: "569.77",
                x2: "1353.42",
                y1: "502.74",
                y2: "502.74",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", {
                attrs: { offset: "0", "stop-color": _vm.startColor }
              }),
              _c("stop", { attrs: { offset: "1", "stop-color": _vm.endColor } })
            ],
            1
          ),
          _c("linearGradient", {
            attrs: {
              id: "b",
              x1: "569.77",
              x2: "1353.42",
              y1: "491.27",
              y2: "491.27",
              "xlink:href": "#a"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "c",
              x1: "569.77",
              x2: "1353.42",
              y1: "426.46",
              y2: "426.46",
              "xlink:href": "#a"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "d",
              x1: "569.77",
              x2: "1353.42",
              y1: "564.74",
              y2: "564.74",
              "xlink:href": "#a"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "e",
              x1: "569.77",
              x2: "1353.42",
              y1: "491.95",
              y2: "491.95",
              "xlink:href": "#a"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "f",
              x1: "569.77",
              x2: "1353.42",
              y1: "513.24",
              y2: "513.24",
              "xlink:href": "#a"
            }
          })
        ],
        1
      ),
      _c("path", {
        attrs: {
          fill: "url(#a)",
          d:
            "M733.31 544a2.88 2.88 0 0 0-3-1.72 69.37 69.37 0 0 1-8.56.63c-6.75 0-11.18-1.9-13.92-5.06-2.32-2.74-3.38-6.54-3.38-12.87V486h21.78a3 3 0 0 0 2.69-1.77 73.61 73.61 0 0 1 12.18-18.73 1.62 1.62 0 0 0-1.21-2.7h-35.46v-21.75a2.7 2.7 0 0 0-3.63-2.54l-18.46 6.75a8.11 8.11 0 0 0-5.32 7.62v68.91c0 14.55 2.32 23.62 7.59 30.58 7 9.28 19 14.77 35.43 14.77a82.45 82.45 0 0 0 17.08-2.14c2.33-.48 4.5-.93 6.42-1.41a1.36 1.36 0 0 0 .68-2.21A73.4 73.4 0 0 1 733.31 544z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "url(#b)",
          d:
            "M591.86 456.68c0-11.38 12-16 22.77-16a39.81 39.81 0 0 1 24.61 8.89 1.09 1.09 0 0 0 1.61-.19l13.44-19.2a1.11 1.11 0 0 0-.25-1.53c-13.63-9.85-27.28-13.28-41.94-13.28-24.46 0-48.72 15.18-48.72 41.55 0 16.66 9.28 26.78 20.88 33.53 19.83 11.6 47.24 13.92 47.24 32.9 0 10.76-8.85 18.56-21.51 18.56-13.08 0-25.58-6.54-34-15.4a1.08 1.08 0 0 0-1.57 0l-17 17.75a1.11 1.11 0 0 0 .06 1.59c15 13.46 33.06 21.38 52.51 21.38 26.58 0 50-18.14 50-44.29.01-49.8-68.13-42.42-68.13-66.26z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "url(#c)",
          d:
            "M1043.24 443.72A17.56 17.56 0 0 0 1058 428c1-11.73-9.16-20.72-21-18.83a17.56 17.56 0 0 0-14.72 15.67c-1.04 11.77 9.12 20.77 20.96 18.88z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "url(#d)",
          d:
            "M1362.41 455.68a58.78 58.78 0 0 1-14.13 3.69c-4.9.66-18.45 2.45-26 3.38-14.06 1.74-22.78 7.27-32.43 18.78-11.61-12.11-25.57-18.71-39.79-18.71a50.17 50.17 0 0 0-21.7 5.08c-24.87 11.87-35.3 35.37-28.64 64.48l.09.33c.15.48 3.68 11.7 10.33 18.14 3.29 3.2 10.62 9.85 17.32 15.89-1.5 1.25-3 2.55-4.54 3.91a95.38 95.38 0 0 0-16.9 19.26c-10.59 16.51-12.76 35.44-6 51.94 6.4 15.53 20 26.64 37.4 30.5a60.81 60.81 0 0 0 13.14 1.45c23.91 0 44.63-14.13 51.55-35.16 6.79-20.63 2.15-40.06-13.8-57.76-8.73-9.69-19.13-17.33-29.18-24.73-2-1.43-3.89-2.85-5.82-4.3-8.61-6.43-16.75-12.51-22.52-21-6.45-9.47-6.57-20.64-.32-29.89 5.92-8.77 15.53-12.26 25.36-10.66 27.09 4.43 34 32.36 34 32.36 7-28.15 33.66-32.44 33.66-32.44 27.32-6.21 34.21-21.85 36-27.77.83-2.65 3.56-6.81 2.92-6.77zm-117.48 131.65q2.6-2 5.5-4c10.27 7.17 19.61 14.82 24.34 26.31 4.8 11.68 1.73 24.89-7.46 32.13a26.36 26.36 0 0 1-16.28 5.51 28.49 28.49 0 0 1-16.07-5c-10.39-7.07-13.57-21.36-7.57-34 3.75-7.8 9.42-14.63 17.54-20.95z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "url(#e)",
          d:
            "M1134.14 462.82h-4.38c-17.51 0-30 6.33-38.39 16.45-6.33 7.52-9.74 17-10.14 32.57v7c-.15 9.23-.13 15.53-.63 21-.32 3.43-8.1 2.79-15.44 1.72a69.89 69.89 0 0 1-13.13-3.8v-72.32a2.7 2.7 0 0 0-2.7-2.7h-22a2.7 2.7 0 0 0-2.71 2.7v72.38c-21 5.89-31.21 5.06-31.21 5.06-6.75 0-11.18-1.9-13.92-5.06-2.32-2.74-3.38-6.54-3.38-12.87V419.39a2.7 2.7 0 0 0-3.63-2.54l-18.47 6.78a8.11 8.11 0 0 0-5.32 7.61v90.55c0 14.55 2.32 23.62 7.6 30.58 7 9.28 19 14.77 35.43 14.77a29.73 29.73 0 0 0 5.1-.24c1.74-.15 3.53-.37 5.42-.67 7.05-1 18.41-3.12 36.28-7.41 26.28 9.44 39.36 8.36 39.36 8.36 17.73 0 29.17-5.48 29.81-24.39 0-.15.5-11 .65-18V514c0-10.13 2.31-16.42 6.11-20.22 3.58-3.58 9.07-5.69 15.4-5.69h4.38c6.33 0 11.81 2.11 15.4 5.69 3.79 3.8 6.11 9.07 6.11 19.2v51.49a2.71 2.71 0 0 0 2.71 2.71h22a2.7 2.7 0 0 0 2.7-2.71v-49.34c0-17.51-3.8-27.85-10.55-35.86-8.51-10.12-20.95-16.45-38.46-16.45z"
        }
      }),
      _c("path", {
        attrs: {
          fill: "url(#f)",
          d:
            "M932 459.09a73.32 73.32 0 0 0-33.75 8.42 47.53 47.53 0 0 0-24.93 42v15.66c-.09 6 .26 6.44-.14 10.58 0 0-1 8.09-15.34 3.14-8.58-3.16-8.79-6.7-9.1-11.57-.38-5.9 0-8.25-.06-12.51v-.54c0-34.65-33.16-61.56-69.47-50.72a51.32 51.32 0 0 0-34.4 34.4c-10.81 36.29 16.11 69.45 50.76 69.45 13.91 0 22-1.81 35.31-13.51 7.22 7.76 19.89 13.32 38.52 13.32a66.33 66.33 0 0 0 15-1.51c16.45-3.7 15.73-19 15.79-22.67 0-.15.19-12.61.22-19.35v-11.43a24.45 24.45 0 0 1 15.84-23 49.76 49.76 0 0 1 15.84-3.13 1.63 1.63 0 0 0 1.6-1.62v-23.8a1.62 1.62 0 0 0-1.69-1.61zm-108.32 58a28.27 28.27 0 1 1-31-31 28.36 28.36 0 0 1 31.01 31.04z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }